<template>
  <transition name="fade" mode="out-in" appear>
    <div v-if="show" class="contact-container">
      <h1>Contact Me</h1>
      <div class="group">
        <p><span class="highlight">Primary Email: </span> prodijvy@gmail.com</p>
      </div>
      <div class="group">
        <p>
          <span class="highlight">Music Submissions: </span> demmiigod@gmail.com
          or contact my <span class="line">discord: jjvy#2477</span>
        </p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return { show: true };
  },
};
</script>

<style scoped>
.contact-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.highlight {
  color: #b94242;
  font-weight: bold;
}

.line {
  text-decoration: underline;
  cursor: pointer;
}
</style>
